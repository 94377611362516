import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0ff12d4c = () => interopDefault(import('../pages/bestellung/index.vue' /* webpackChunkName: "pages/bestellung/index" */))
const _cc65e852 = () => interopDefault(import('../pages/heizoelpreise/index.vue' /* webpackChunkName: "pages/heizoelpreise/index" */))
const _9c7dd5cc = () => interopDefault(import('../pages/heizoelpreise/index/index.vue' /* webpackChunkName: "pages/heizoelpreise/index/index" */))
const _64955c49 = () => interopDefault(import('../pages/heizoeltank/index.vue' /* webpackChunkName: "pages/heizoeltank/index" */))
const _f27aaa9c = () => interopDefault(import('../pages/heizoeltank/index/betriebsanleitungen.vue' /* webpackChunkName: "pages/heizoeltank/index/betriebsanleitungen" */))
const _6c01bc90 = () => interopDefault(import('../pages/heizoeltank/index/e-peilstab.vue' /* webpackChunkName: "pages/heizoeltank/index/e-peilstab" */))
const _753398f6 = () => interopDefault(import('../pages/heizoeltank/index/fachbetriebe.vue' /* webpackChunkName: "pages/heizoeltank/index/fachbetriebe" */))
const _3932fcb2 = () => interopDefault(import('../pages/heizoeltank/index/peiltabellen.vue' /* webpackChunkName: "pages/heizoeltank/index/peiltabellen" */))
const _05fdf209 = () => interopDefault(import('../pages/heizoeltank/index/t-peilstab.vue' /* webpackChunkName: "pages/heizoeltank/index/t-peilstab" */))
const _c6031df0 = () => interopDefault(import('../pages/heizoeltank/index/tankreinigung.vue' /* webpackChunkName: "pages/heizoeltank/index/tankreinigung" */))
const _5dfd6308 = () => interopDefault(import('../pages/infos/index.vue' /* webpackChunkName: "pages/infos/index" */))
const _8fcfcfde = () => interopDefault(import('../pages/infos/index/bewertungen.vue' /* webpackChunkName: "pages/infos/index/bewertungen" */))
const _79b75948 = () => interopDefault(import('../pages/infos/index/co2-steuer.vue' /* webpackChunkName: "pages/infos/index/co2-steuer" */))
const _9acbecfa = () => interopDefault(import('../pages/infos/index/faq.vue' /* webpackChunkName: "pages/infos/index/faq" */))
const _64ccdf2f = () => interopDefault(import('../pages/infos/index/heizoel-sorten.vue' /* webpackChunkName: "pages/infos/index/heizoel-sorten" */))
const _21348d69 = () => interopDefault(import('../pages/infos/index/hvo-heizoel.vue' /* webpackChunkName: "pages/infos/index/hvo-heizoel" */))
const _3ce613c3 = () => interopDefault(import('../pages/infos/index/qualitaetsmanagement.vue' /* webpackChunkName: "pages/infos/index/qualitaetsmanagement" */))
const _9b278bbc = () => interopDefault(import('../pages/infos/index/ratenkauf.vue' /* webpackChunkName: "pages/infos/index/ratenkauf" */))
const _53e2f798 = () => interopDefault(import('../pages/kundendaten-loeschen/index.vue' /* webpackChunkName: "pages/kundendaten-loeschen/index" */))
const _1d403a92 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _d471e8d6 = () => interopDefault(import('../pages/news/index/index.vue' /* webpackChunkName: "pages/news/index/index" */))
const _5dcac05a = () => interopDefault(import('../pages/news/index/archiv/index.vue' /* webpackChunkName: "pages/news/index/archiv/index" */))
const _64ace0a2 = () => interopDefault(import('../pages/news/index/charts.vue' /* webpackChunkName: "pages/news/index/charts" */))
const _38a406b8 = () => interopDefault(import('../pages/news/index/archiv/archive-category.vue' /* webpackChunkName: "pages/news/index/archiv/archive-category" */))
const _495cef3d = () => interopDefault(import('../pages/news/index/_id.vue' /* webpackChunkName: "pages/news/index/_id" */))
const _be958794 = () => interopDefault(import('../pages/oelpreis/index.vue' /* webpackChunkName: "pages/oelpreis/index" */))
const _11bfc337 = () => interopDefault(import('../pages/optout.vue' /* webpackChunkName: "pages/optout" */))
const _2af3bc5d = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _47a6c920 = () => interopDefault(import('../pages/services/index/index.vue' /* webpackChunkName: "pages/services/index/index" */))
const _3e07df22 = () => interopDefault(import('../pages/services/index/app.vue' /* webpackChunkName: "pages/services/index/app" */))
const _64c7cefd = () => interopDefault(import('../pages/services/index/heizoellieferung.vue' /* webpackChunkName: "pages/services/index/heizoellieferung" */))
const _37358a74 = () => interopDefault(import('../pages/services/index/heizoelverbrauch.vue' /* webpackChunkName: "pages/services/index/heizoelverbrauch" */))
const _9bcbc350 = () => interopDefault(import('../pages/services/index/historien-preisrechner.vue' /* webpackChunkName: "pages/services/index/historien-preisrechner" */))
const _689155cc = () => interopDefault(import('../pages/services/index/oelheizung.vue' /* webpackChunkName: "pages/services/index/oelheizung" */))
const _1e61a01e = () => interopDefault(import('../pages/services/index/tipps-tricks.vue' /* webpackChunkName: "pages/services/index/tipps-tricks" */))
const _5fb1a584 = () => interopDefault(import('../pages/unternehmen/index.vue' /* webpackChunkName: "pages/unternehmen/index" */))
const _54b60df2 = () => interopDefault(import('../pages/unternehmen/index/index.vue' /* webpackChunkName: "pages/unternehmen/index/index" */))
const _7351d882 = () => interopDefault(import('../pages/unternehmen/index/esyoil.vue' /* webpackChunkName: "pages/unternehmen/index/esyoil" */))
const _9f9ad918 = () => interopDefault(import('../pages/unternehmen/index/jobs/index.vue' /* webpackChunkName: "pages/unternehmen/index/jobs/index" */))
const _a5a23f90 = () => interopDefault(import('../pages/unternehmen/index/team.vue' /* webpackChunkName: "pages/unternehmen/index/team" */))
const _5af92686 = () => interopDefault(import('../pages/unternehmen/index/unternehmer.vue' /* webpackChunkName: "pages/unternehmen/index/unternehmer" */))
const _152e407f = () => interopDefault(import('../pages/bestellung/e-peilstab-plus.vue' /* webpackChunkName: "pages/bestellung/e-peilstab-plus" */))
const _dace4ef8 = () => interopDefault(import('../pages/bestellung/heizoel-checkout/index.vue' /* webpackChunkName: "pages/bestellung/heizoel-checkout/index" */))
const _45cc27f2 = () => interopDefault(import('../pages/bestellung/heizoel-checkout/index/index.vue' /* webpackChunkName: "pages/bestellung/heizoel-checkout/index/index" */))
const _5438ca24 = () => interopDefault(import('../pages/bestellung/heizoel-checkout/index/adresse.vue' /* webpackChunkName: "pages/bestellung/heizoel-checkout/index/adresse" */))
const _a99732b2 = () => interopDefault(import('../pages/bestellung/heizoel-checkout/index/entladestellen.vue' /* webpackChunkName: "pages/bestellung/heizoel-checkout/index/entladestellen" */))
const _da764bac = () => interopDefault(import('../pages/bestellung/heizoel-checkout/index/ratenkauf.vue' /* webpackChunkName: "pages/bestellung/heizoel-checkout/index/ratenkauf" */))
const _bcd1e110 = () => interopDefault(import('../pages/bestellung/heizoel-checkout/index/success.vue' /* webpackChunkName: "pages/bestellung/heizoel-checkout/index/success" */))
const _39c40556 = () => interopDefault(import('../pages/bestellung/heizoel-checkout/index/zusammenfassung.vue' /* webpackChunkName: "pages/bestellung/heizoel-checkout/index/zusammenfassung" */))
const _3adf4da4 = () => interopDefault(import('../pages/heizoelpreise/bundeslaender/index.vue' /* webpackChunkName: "pages/heizoelpreise/bundeslaender/index" */))
const _940c61a4 = () => interopDefault(import('../pages/unternehmen/agb.vue' /* webpackChunkName: "pages/unternehmen/agb" */))
const _4af949d5 = () => interopDefault(import('../pages/unternehmen/datenschutz.vue' /* webpackChunkName: "pages/unternehmen/datenschutz" */))
const _126354ae = () => interopDefault(import('../pages/unternehmen/impressum.vue' /* webpackChunkName: "pages/unternehmen/impressum" */))
const _47253380 = () => interopDefault(import('../pages/unternehmen/presse.vue' /* webpackChunkName: "pages/unternehmen/presse" */))
const _85fd268c = () => interopDefault(import('../pages/charts/charts/charts-screenshot-service.vue' /* webpackChunkName: "pages/charts/charts/charts-screenshot-service" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _72740e7a = () => interopDefault(import('../pages/heizoelhaendler/heizoel-haendlerliste/_id.vue' /* webpackChunkName: "pages/heizoelhaendler/heizoel-haendlerliste/_id" */))
const _96c5a01c = () => interopDefault(import('../pages/heizoelpreise/bundeslaender/_category/index.vue' /* webpackChunkName: "pages/heizoelpreise/bundeslaender/_category/index" */))
const _389901dd = () => interopDefault(import('../pages/heizoelpreise/bundeslaender/_category/_id/index.vue' /* webpackChunkName: "pages/heizoelpreise/bundeslaender/_category/_id/index" */))
const _17ba1066 = () => interopDefault(import('../pages/amp/_id/index.vue' /* webpackChunkName: "pages/amp/_id/index" */))
const _581816a1 = () => interopDefault(import('../pages/heizoelhaendler/_id/index.vue' /* webpackChunkName: "pages/heizoelhaendler/_id/index" */))
const _440a17ff = () => interopDefault(import('../pages/heizoelpreise/_id.vue' /* webpackChunkName: "pages/heizoelpreise/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bestellung",
    component: _0ff12d4c,
    name: "bestellung"
  }, {
    path: "/heizoelpreise",
    component: _cc65e852,
    children: [{
      path: "",
      component: _9c7dd5cc,
      name: "heizoelpreise-index"
    }]
  }, {
    path: "/heizoeltank",
    component: _64955c49,
    name: "heizoeltank",
    children: [{
      path: "betriebsanleitungen",
      component: _f27aaa9c,
      name: "heizoeltank-index-betriebsanleitungen"
    }, {
      path: "e-peilstab",
      component: _6c01bc90,
      name: "heizoeltank-index-e-peilstab"
    }, {
      path: "fachbetriebe",
      component: _753398f6,
      name: "heizoeltank-index-fachbetriebe"
    }, {
      path: "peiltabellen",
      component: _3932fcb2,
      name: "heizoeltank-index-peiltabellen"
    }, {
      path: "t-peilstab",
      component: _05fdf209,
      name: "heizoeltank-index-t-peilstab"
    }, {
      path: "tankreinigung",
      component: _c6031df0,
      name: "heizoeltank-index-tankreinigung"
    }]
  }, {
    path: "/infos",
    component: _5dfd6308,
    name: "infos",
    children: [{
      path: "bewertungen",
      component: _8fcfcfde,
      name: "infos-index-bewertungen"
    }, {
      path: "co2-steuer",
      component: _79b75948,
      name: "infos-index-co2-steuer"
    }, {
      path: "faq",
      component: _9acbecfa,
      name: "infos-index-faq"
    }, {
      path: "heizoel-sorten",
      component: _64ccdf2f,
      name: "infos-index-heizoel-sorten"
    }, {
      path: "hvo-heizoel",
      component: _21348d69,
      name: "infos-index-hvo-heizoel"
    }, {
      path: "qualitaetsmanagement",
      component: _3ce613c3,
      name: "infos-index-qualitaetsmanagement"
    }, {
      path: "ratenkauf",
      component: _9b278bbc,
      name: "infos-index-ratenkauf"
    }]
  }, {
    path: "/kundendaten-loeschen",
    component: _53e2f798,
    name: "kundendaten-loeschen"
  }, {
    path: "/news",
    component: _1d403a92,
    children: [{
      path: "",
      component: _d471e8d6,
      name: "news-index"
    }, {
      path: "archiv",
      component: _5dcac05a,
      name: "news-index-archiv"
    }, {
      path: "charts",
      component: _64ace0a2,
      name: "news-index-charts"
    }, {
      path: "archiv/archive-category",
      component: _38a406b8,
      name: "news-index-archiv-archive-category"
    }, {
      path: ":id",
      component: _495cef3d,
      name: "news-index-id"
    }]
  }, {
    path: "/oelpreis",
    component: _be958794,
    name: "oelpreis"
  }, {
    path: "/optout",
    component: _11bfc337,
    name: "optout"
  }, {
    path: "/services",
    component: _2af3bc5d,
    children: [{
      path: "",
      component: _47a6c920,
      name: "services-index"
    }, {
      path: "app",
      component: _3e07df22,
      name: "services-index-app"
    }, {
      path: "heizoellieferung",
      component: _64c7cefd,
      name: "services-index-heizoellieferung"
    }, {
      path: "heizoelverbrauch",
      component: _37358a74,
      name: "services-index-heizoelverbrauch"
    }, {
      path: "historien-preisrechner",
      component: _9bcbc350,
      name: "services-index-historien-preisrechner"
    }, {
      path: "oelheizung",
      component: _689155cc,
      name: "services-index-oelheizung"
    }, {
      path: "tipps-tricks",
      component: _1e61a01e,
      name: "services-index-tipps-tricks"
    }]
  }, {
    path: "/unternehmen",
    component: _5fb1a584,
    children: [{
      path: "",
      component: _54b60df2,
      name: "unternehmen-index"
    }, {
      path: "esyoil",
      component: _7351d882,
      name: "unternehmen-index-esyoil"
    }, {
      path: "jobs",
      component: _9f9ad918,
      name: "unternehmen-index-jobs"
    }, {
      path: "team",
      component: _a5a23f90,
      name: "unternehmen-index-team"
    }, {
      path: "unternehmer",
      component: _5af92686,
      name: "unternehmen-index-unternehmer"
    }]
  }, {
    path: "/bestellung/e-peilstab-plus",
    component: _152e407f,
    name: "bestellung-e-peilstab-plus"
  }, {
    path: "/bestellung/heizoel-checkout",
    component: _dace4ef8,
    children: [{
      path: "",
      component: _45cc27f2,
      name: "bestellung-heizoel-checkout-index"
    }, {
      path: "adresse",
      component: _5438ca24,
      name: "bestellung-heizoel-checkout-index-adresse"
    }, {
      path: "entladestellen",
      component: _a99732b2,
      name: "bestellung-heizoel-checkout-index-entladestellen"
    }, {
      path: "ratenkauf",
      component: _da764bac,
      name: "bestellung-heizoel-checkout-index-ratenkauf"
    }, {
      path: "success",
      component: _bcd1e110,
      name: "bestellung-heizoel-checkout-index-success"
    }, {
      path: "zusammenfassung",
      component: _39c40556,
      name: "bestellung-heizoel-checkout-index-zusammenfassung"
    }]
  }, {
    path: "/heizoelpreise/bundeslaender",
    component: _3adf4da4,
    name: "heizoelpreise-bundeslaender"
  }, {
    path: "/unternehmen/agb",
    component: _940c61a4,
    name: "unternehmen-agb"
  }, {
    path: "/unternehmen/datenschutz",
    component: _4af949d5,
    name: "unternehmen-datenschutz"
  }, {
    path: "/unternehmen/impressum",
    component: _126354ae,
    name: "unternehmen-impressum"
  }, {
    path: "/unternehmen/presse",
    component: _47253380,
    name: "unternehmen-presse"
  }, {
    path: "/charts/charts/charts-screenshot-service",
    component: _85fd268c,
    name: "charts-charts-charts-screenshot-service"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }, {
    path: "/heizoelhaendler/heizoel-haendlerliste/:id?",
    component: _72740e7a,
    name: "heizoelhaendler-heizoel-haendlerliste-id"
  }, {
    path: "/heizoelpreise/bundeslaender/:category",
    component: _96c5a01c,
    name: "heizoelpreise-bundeslaender-category"
  }, {
    path: "/heizoelpreise/bundeslaender/:category/:id",
    component: _389901dd,
    name: "heizoelpreise-bundeslaender-category-id"
  }, {
    path: "/amp/:id",
    component: _17ba1066,
    name: "amp-id"
  }, {
    path: "/heizoelhaendler/:id",
    component: _581816a1,
    name: "heizoelhaendler-id"
  }, {
    path: "/heizoelpreise/:id",
    component: _440a17ff,
    name: "heizoelpreise-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
